<template>
  <div class="wrapper">
    <div class="row between-xs">
      <div class="col-xs">
        <h2>Content Vertical List Search</h2>
      </div>
      <div class="col-xs-1 close" @click="$emit('close')">
        <font-awesome-icon
          :icon="['fal', 'times']"
          size="2x"
        ></font-awesome-icon>
      </div>
    </div>
    <div class="row middle-xs between-xs">
      <div class="col-xs-6">
        <label for>
          Category:
          <v-select
            :options="categories"
            multiple
            v-model="selected_categories"
          ></v-select>
        </label>
      </div>

      <div class="col-xs-6">
        <label for>
          Filter:
          <input type="text" v-model="filter" :disabled="!filterEnabled" />
        </label>
      </div>
    </div>
    <div class="row end-xs">
      <div class="col-xs-4">
        <button
          class="button solid"
          :disabled="!filterEnabled"
          v-clipboard="() => selected_categories.join(', ')"
        >
          {{ copyText }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs">
        <div class="title">Results</div>
      </div>
    </div>
    <div class="results">
      <div
        class="row result middle-xs"
        v-for="(result, idx) in filteredResults"
        :key="'allow_' + idx"
      >
        <div class="col-xs">{{ result }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      query: "",
      size: 50,
      offset: 0,
      selected_category: "",
      selected_categories: [],
      filter: "",
    };
  },

  async mounted() {
    await this.getPMPAllowList();
  },
  beforeDestroy() {
    this.$store.commit("SET_WHITELIST", []);
  },
  computed: {
    ...mapState({ whitelist: "whitelist" }),
    copyText() {
      return this.selected_categories.length > 1
        ? "Copy Categories"
        : "Copy Category";
    },
    categories() {
      return Object.keys(this.whitelist) || [];
    },
    results() {
      let results = [];
      this.selected_categories.forEach((category) => {
        results = results.concat(this.whitelist[category]);
      });

      return results;
    },
    filteredResults() {
      return this.results.filter((result) =>
        result.toLowerCase().includes(this.filter)
      );
    },

    filterEnabled() {
      return this.selected_categories.length > 0;
    },
  },
  methods: {
    ...mapActions({ getPMPAllowList: "getPMPAllowList" }),
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 10px 20px;
}
.results {
  height: 350px;
  margin-top: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
.result {
  padding: 10px;

  border-bottom: 1px solid #eaeaea;
  &:hover {
    background-color: #eaeaea;
  }
}
h2 {
  margin-top: 15px;
}
.title {
  margin-bottom: 0;
}
.close {
  cursor: pointer;
}
.button.outline:hover {
  color: #444;
  background-color: #fdce07;
}
</style>
